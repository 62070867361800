<template>
    <painel titulo="Perfis de Acesso" icone="pi pi-sliders-h" :refreshFunction="atualizar" v-show="mostrarListagem">
        <tabela :data="perfis" dataKey="perfilAcessoId" :globalFilterFields="['codPerfilAcesso', 'descricaoPerfilAcesso']" stateKey="dt-perfisacesso">
            <template #botoes>
                <btn-inserir></btn-inserir>
            </template>
            <template #conteudo>
                <Column headerStyle="width: 3em">
                    <template #body="slotProps">
                        <btn-detalhar :perfil="slotProps.data"></btn-detalhar>
                    </template>
                </Column>
                <Column field="codPerfilAcesso" header="Código" :sortable="true">
                    <template #body="slotProps">
                        {{ slotProps.data.codPerfilAcesso }}
                    </template>
                </Column>
                <Column field="descricaoPerfilAcesso" header="Descrição" :sortable="true">
                    <template #body="slotProps">
                        {{ slotProps.data.descricaoPerfilAcesso }}
                    </template>
                </Column>
            </template>
        </tabela>
    </painel>
    <router-view></router-view>
</template>

<script>
import PerfisServices from './services';
import BtnDetalhar from './BtnDetalhar';
import BtnInserir from './BtnInserir';

export default {
    components: {
        BtnDetalhar,
        BtnInserir,
    },

    data() {
        return {
            perfis: null,
        };
    },

    methods: {
        obterPerfis() {
            this.$store.dispatch('addRequest');
            PerfisServices.obterTodos().then((response) => {
                if (response?.success) {
                    this.perfis = response.data;
                } else {
                    this.perfis = null;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        atualizar() {
            this.obterPerfis();
        },
    },

    mounted() {
        this.obterPerfis();
    },

    computed: {
        mostrarListagem() {
            return this.$route.name === 'Perfis';
        },
    },

    watch: {
        $route(to) {
            if (to.name === 'Perfis') {
                if (this.$store.getters.atualizar) {
                    this.obterPerfis();
                    this.$store.dispatch('setAtualizar', false);
                }
            }
        },
    },
};
</script>
